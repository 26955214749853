import React, { ReactNode } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { twMerge } from "tailwind-merge";
import { FreeMode } from "swiper/modules";

interface SwipeCardsProps {
  items: ReactNode[];
  slidesPerView?: number;
  className?: string;
}

const SwipeCards: React.FC<SwipeCardsProps> = ({
  items,
  slidesPerView = 1.2,
  className = "",
}) => {
  const mergedClasses = twMerge("swiper-container", className);

  return (
    <div className="block md:hidden">
      <Swiper
        spaceBetween={16}
        slidesPerView={"auto"} // cards visible + peek
        className={mergedClasses}
        freeMode={{
          enabled: true,
          sticky: true,
          momentumBounce: false,
        }}
        resistanceRatio={0}
        slidesOffsetBefore={0}
        slidesOffsetAfter={0}
        modules={[FreeMode]}
      >
        {items.map((item, index) => (
          <SwiperSlide
            key={index}
            style={{
              width: 100 / slidesPerView - 12 / slidesPerView + `vw`,
              marginRight: "16px",
            }}
          >
            {item}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwipeCards;
